define('mylibrary/models/reference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    filename: _emberData.default.attr(),
    filesize: _emberData.default.attr(),
    title: _emberData.default.attr(),
    author: _emberData.default.attr(),
    subject: _emberData.default.attr(),
    category: _emberData.default.attr(),
    subcategory1: _emberData.default.attr(),
    subcategory2: _emberData.default.attr(),
    subcategory3: _emberData.default.attr(),
    year: _emberData.default.attr(),
    image: _emberData.default.attr(),
    link: _emberData.default.attr()
  });
});